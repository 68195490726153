import React, { useState } from "react";

import { SeoComponent } from "../components/SeoComponent";
import SimpleStandings from "../components/SimpleStandings";
import SimpleFixture from "../components/SimpleFixture";
import StatisticsCard from "../components/StatisticsCard";
import TabularButton from "../components/TabularButton";
import { useAllMatches } from "../hooks/useAllMatches";
import { useStandings } from "../hooks/useStandings";
import { useAllStats } from "../hooks/useAllStats";

const closestMatchesToFind = 9;

const findClosestMatches = (fixture, target, refDay) => {
  let filtered = [];
  let prev = target - 1;
  let next = target;
  let prevDif;
  let nextDif;
  if (prev > -1) prevDif = Math.abs(refDay - fixture[prev].date);
  if (next < fixture.length) nextDif = Math.abs(refDay - fixture[next].date);

  while (filtered.length < closestMatchesToFind) {
    if (prev < 0) {
      while (filtered.length < closestMatchesToFind) {
        if (next > fixture.length - 1) {
          return filtered;
        }
        filtered.push(fixture[next]);
        next++;
      }
      return filtered;
    } else if (next > fixture.length - 1) {
      while (filtered.length < closestMatchesToFind) {
        if (prev < 0) return filtered;
        filtered.push(fixture[prev]);
        prev--;
      }
      return filtered;
    } else if (prevDif > nextDif) {
      filtered.push(fixture[next]);
      next++;
      if (next < fixture.length)
        nextDif = Math.abs(refDay - fixture[next].date);
    } else {
      filtered.push(fixture[prev]);
      prev--;
      if (prev >= 0) prevDif = Math.abs(refDay - fixture[prev].date);
    }
  }
  return filtered;
};

const extractCloseMatches = (fixture) => {
  const today = new Date();
  let filtered = [];
  if (today < fixture[0].date) {
    filtered = fixture.slice(0, closestMatchesToFind);
  } else if (today > fixture[fixture.length - 1].date) {
    filtered = fixture.slice(
      fixture.length - closestMatchesToFind,
      fixture.length
    );
  } else {
    let startInd = 0;
    let endInd = fixture.length - 1;
    let target = undefined;

    while (endInd > startInd) {
      let length = endInd - startInd + 1;
      let middleInd = startInd + Math.floor(length / 2);

      if (fixture[middleInd].date.getTime() === today.getTime()) {
        target = middleInd;
        break;
      }

      if (fixture[middleInd].date.getTime() < today.getTime()) {
        startInd = middleInd + 1;
      } else {
        endInd = middleInd - 1;
      }
    }

    if (target === undefined) {
      if (
        Math.abs(fixture[startInd].date.getTime() - today.getTime()) <
        Math.abs(fixture[startInd + 1].date.getTime() - today.getTime())
      )
        target = startInd;
      else target = startInd + 1;
    }
    filtered = findClosestMatches(fixture, target, today);
  }

  if (filtered.length > 0) {
    return filtered.sort((a, b) => a.date - b.date);
  }

  return filtered;
};

function findStat(allStats, statName) {
  return allStats.find((item) => item.name === statName);
}

const StatisticsCardWrapper = ({ stat, className }) => (
  <StatisticsCard
    className={`lg:mt-0 lg:w-60 xl:w-80 lg:h-64 ${className}`}
    statistics={stat.statistics}
    label={stat.label}
    textToAppend={stat.textToAppend}
    slug={stat.slug}
    showLink={false}
  />
);

export default function Home() {
  const allStats = useAllStats();
  const { allMatches } = useAllMatches();
  const standings = useStandings();
  const [showStandings, setShowStandings] = useState(true);
  const closeMatches = extractCloseMatches(allMatches);
  return (
    <>
      <div className="lg:hidden w-full dark:bg-gray-900 ">
        <h1 className="text-pink-500 font-bold text-center my-2">
          Süper Lig Puan Durumu ve Fikstürü
        </h1>
        <TabularButton
          isActive={showStandings}
          onClick={() => setShowStandings(true)}
          label="Puan Durumu"
        />
        <TabularButton
          isActive={!showStandings}
          onClick={() => setShowStandings(false)}
          label="Fikstür"
        />
        {showStandings ? (
          <SimpleStandings standings={standings} />
        ) : (
          <SimpleFixture fixture={closeMatches} fixtureUrl="/fikstur" />
        )}
      </div>
      <div className="hidden w-full lg:flex flex-col">
        <h1 className="text-pink-500 font-bold text-center mb-4">
          Süper Lig Puan Durumu ve Fikstürü
        </h1>
        <div className="flex justify-center w-full dark:bg-gray-900">
          {allStats[0].statistics && (
            <div className="hidden lg:flex lg:flex-col">
              <StatisticsCardWrapper
                className="lg:mr-0"
                stat={findStat(allStats, "ftFor")}
              />
              <StatisticsCardWrapper
                className="lg:mr-0"
                stat={findStat(allStats, "avgXg")}
              />
              <StatisticsCardWrapper
                className="lg:mr-0"
                stat={findStat(allStats, "totalShots")}
              />
            </div>
          )}
          <div className="hidden lg:inline-block lg:mr-4 lg:ml-4 lg:basis-96">
            <SimpleStandings standings={standings} />
          </div>
          <div className="hidden lg:inline-block lg:mr-4 lg:basis-96">
            <SimpleFixture fixture={closeMatches} fixtureUrl="/fikstur" />
          </div>
          {allStats[0].statistics && (
            <div className="hidden lg:flex lg:flex-col">
              <StatisticsCardWrapper
                className="lg:ml-0"
                stat={findStat(allStats, "ftAgainst")}
              />
              <StatisticsCardWrapper
                className="lg:ml-0"
                stat={findStat(allStats, "avgBallPossession")}
              />
              <StatisticsCardWrapper
                className="lg:ml-0"
                stat={findStat(allStats, "goalkeeperSaves")}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export const Head = () => (
  <SeoComponent keywords="fikstür, fikstur, puan durumu, puan tablosu, Süperlig, Superlig, ensuperlig, ensüperlig, Futbol, Maçlar, Maclar, Spor, Galatasaray, Fenerbahçe, Beşiktaş, Trabzonspor, lig istatistikleri, maç sonuçları, mac sonuclari" />
);
